<template>
  <div class="comin-soon">
    <div class="text">
      <h1>Coming soon</h1>
      <p>KEEP IN TOUCH</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComingSoon",
  components: {}
};
</script>

<style lang="scss">
.comin-soon {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background: #fff;
  pointer-events: none;
  z-index: 10;
  scroll-behavior: none;
  overflow: hidden;
  .text {
    padding: 40px;
    max-width: 600px;
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: -40%;
      top: -50%;
      width: 30rem;
      height: 25rem;
      border-radius: 30px;
      // transform: rotate(30deg);
      background: linear-gradient(180deg, #fe801b 0%, #fdc394 100%);
      filter: blur(60px);
      z-index: -1;
      opacity: 0.6;
      animation: blob 3s linear infinite;
      transition: 0.5s ease-in-out;
    }
    &:after {
      left: -10%;
      transform: rotate(-30deg);
      background: linear-gradient(
        180deg,
        #50ac95b7 0%,
        rgba(201, 227, 220, 0.33) 100%
      );
      animation: blob 7s linear infinite;
      transition: 0.5s ease-in-out;
    }
    @keyframes blob {
      0% {
        right: 20rem;
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(90deg);
        filter: blur(74px);
      }
      100% {
        transform: rotate(0deg);
      }
    }
    h1 {
      font-size: 4rem;
      text-transform: uppercase;
      color: #fe801b;
      text-align: center;
    }
    p {
      text-transform: uppercase;
      letter-spacing: 4px;
      text-align: center;
    }
  }
}
</style>
