import Http from "@/services/Http";
export default {
  getStandards(data) {
    return Http().post("classes", data);
  },
  getSchools(data) {
    return Http().post("school/list", data);
  },
  getCountryCode(data) {
    return Http().post("countries", data);
  },
  getSubjectCategory(data) {
    return Http().post("subject-categories", data);
  },
  getTestimonials(data) {
    return Http().post("testimonials", data);
  },
  getStandardList(data) {
    return Http().post("standards", data);
  },
  getContent(data) {
    return Http().post("content", data);
  },
  getAchievements() {
    return Http().post("achievements");
  },
  saveContact(data) {
    return Http().post("contact-us", data);
  },
  getCompanyDetails(data) {
    return Http().post("contact-us/details", data);
  },
  getContents(data) {
    return Http().post("/pupa/content", data);
  }
};
