<template>
  <div>
    <div>
      <ComingSoon v-if="isComingSoon" />
      <div class="home">
        <div class="container-fluid banner-wrapper">
          <div class="container">
            <div class="row align-items-bottom">
              <div class="col-md-6 d-flex content-col align-items-center">
                <div class="content-wrapper">
                  <h1>
                    {{ banners.title }}
                  </h1>
                  <p>
                    {{ banners.description }}
                  </p>
                  <a class="btn cta-outline" href="#">Know More</a>
                </div>
              </div>
              <div class="col-md-6">
                <img src="../assets/hero-image.gif" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid feature-wrapper">
          <div class="container">
            <div class="row">
              <div class="col-md-3">
                <div class="feature-box">
                  <div class="feature-card">
                    <div class="icon-box">
                      <img src="../assets/feature1.svg" alt="" />
                    </div>
                    <h2>Live Classes</h2>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="feature-box">
                  <div class="feature-card second">
                    <div class="icon-box">
                      <img src="../assets/feature2.svg" alt="" />
                    </div>
                    <h2>Webinars</h2>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="feature-box">
                  <div class="feature-card third">
                    <div class="icon-box">
                      <img src="../assets/feature3.svg" alt="" />
                    </div>
                    <h2>live Interaction</h2>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="feature-box">
                  <div class="feature-card fourth">
                    <div class="icon-box">
                      <img src="../assets/feature4.svg" alt="" />
                    </div>
                    <h2>24/7 support</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid service-wrapper">
          <div class="container">
            <div class="row">
              <div class="col-md-4 align-self-center">
                <ul class="list-unstyled service-list ">
                  <li
                    v-for="(subject, subjectIndex) in subjectCategories.slice(
                      0,
                      4
                    )"
                    :key="subjectIndex"
                  >
                    <span
                      >{{ subjectIndex + 1 }}
                      <svg
                        id="number-bg"
                        viewBox="0 0 200 200"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#FFDD9B"
                          d="M67.2,-23.7C72.2,-6.3,51.2,17.7,26,36C0.8,54.4,-28.7,67.2,-48.1,55.6C-67.6,44,-77,7.9,-67.3,-15.8C-57.6,-39.5,-28.8,-51,1.1,-51.4C31.1,-51.7,62.2,-41,67.2,-23.7Z"
                          transform="translate(100 100)"
                        />
                      </svg>
                    </span>
                    <p>{{ subject.title }}</p>
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <div class="cover-hero-image">
                  <img src="../assets/we-cover-hero.png" alt="" />
                  <img
                    class="service-cloud"
                    src="../assets/service-cloud.svg"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-md-4 align-self-center">
                <ul class="list-unstyled service-list ">
                  <li
                    v-for="(subject, subjectIndex) in subjectCategories.slice(
                      4,
                      8
                    )"
                    :key="subjectIndex"
                  >
                    <span
                      >{{ subjectIndex + 5 }}
                      <svg
                        id="number-bg"
                        viewBox="0 0 200 200"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#FFDD9B"
                          d="M37.9,-41.8C44.7,-31.1,42.6,-15.6,46.1,3.4C49.5,22.4,58.4,44.9,51.6,56.9C44.9,69,22.4,70.7,0.6,70C-21.2,69.4,-42.4,66.5,-56.8,54.4C-71.2,42.4,-78.9,21.2,-80,-1.1C-81.2,-23.5,-75.8,-46.9,-61.3,-57.6C-46.9,-68.3,-23.5,-66.2,-3.9,-62.3C15.6,-58.3,31.1,-52.5,37.9,-41.8Z"
                          transform="translate(100 100)"
                        />
                      </svg>
                    </span>
                    <p>{{ subject.title }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid class-wrapper">
          <transition name="fade">
            <loader v-if="isClassLoader == true"></loader>
          </transition>
          <div class="container" v-if="isClassLoader == false">
            <div class="row">
              <div
                class="col-md-3"
                v-for="(standard, classIndex) in classes.slice(0, 4)"
                :key="classIndex"
              >
                <router-link
                  v-for="(program, programIndex) in standard.programs"
                  :key="programIndex"
                  :to="{ name: 'course', params: { slug: program.slug } }"
                >
                  <div class="class-card">
                    <img :src="getThumbnail(standard.standard)" alt="" />
                    <p>{{ standard.title }}</p>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="row justify-content-center">
              <div
                class="col-md-3"
                v-for="(standard, classIndex) in classes.slice(4, 7)"
                :key="classIndex"
              >
                <router-link
                  v-for="(program, programIndex) in standard.programs"
                  :key="programIndex"
                  :to="{ name: 'course', params: { slug: program.slug } }"
                >
                  <div class="class-card">
                    <img :src="getThumbnail(standard.standard)" alt="" />
                    <p>{{ standard.title }}</p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid speciality-wrapper">
          <div class="container">
            <div class="row">
              <div
                class="col-md-4"
                v-for="(aboveTShapedLearning,
                aboveTShapedLearningIndex) in aboveTShapedLearnings"
                :key="aboveTShapedLearningIndex"
              >
                <div class="item-wrapper">
                  <div class="img-box">
                    <img :src="aboveTShapedLearning.image" alt="" />
                  </div>
                  <div class="item-card">
                    <h5>{{ aboveTShapedLearning.title }}</h5>
                    <p>
                      {{ aboveTShapedLearning.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid explainer-wrapper">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-md-5">
                <div class="image-box">
                  <img src="../assets/graph.png" alt="t-shaped-learner" />
                </div>
              </div>
              <div class="col-md-6 align-self-center">
                <h2>T-Shaped <br />learner</h2>
                <p>
                  {{ tShapedLearning.description }}
                </p>
                <ul class="list-unstyled">
                  <li>
                    <img src="../assets/chat.svg" alt="" />
                  </li>
                  <li>
                    <img src="../assets/Ball.svg" alt="" />
                  </li>
                  <li>
                    <img src="../assets/Diploma.svg" alt="" />
                  </li>
                  <li>
                    <img src="../assets/Pendilum.svg" alt="" />
                  </li>
                  <li>
                    <img src="../assets/globe.svg" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid membership-wrapper">
          <div class="container">
            <div class="row justify-content-end">
              <div class="col-md-10">
                <div class="membership-box">
                  <h2>Be a Member</h2>
                  <p>
                    {{ BeAMember.description }}
                  </p>
                  <router-link
                    :to="{ name: 'membership' }"
                    class="btn cta-outline"
                    >Subscribe now</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid testimonial-wrapper">
      <div class="container">
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div
            class="carousel-indicators"
            v-for="(testimonial, testimonialIndex) in testimonials"
            :key="testimonialIndex"
          >
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              :data-bs-slide-to="testimonialIndex"
              :class="testimonialIndex == 0 ? 'active' : ''"
              :aria-current="testimonialIndex == 0 ? 'true' : 'false'"
              :aria-label="'Slide' + testimonial.id"
            ></button>
          </div>
          <div class="carousel-inner">
            <div
              class="carousel-item "
              v-for="(testimonial, testimonialIndex) in testimonials"
              :key="testimonialIndex"
              :class="testimonialIndex == 0 ? 'active' : ''"
            >
              <div class="row">
                <div class="col-md-3">
                  <div class="credit-wrap">
                    <div class="image-box">
                      <img :src="testimonial.image" alt="user image" />
                    </div>
                    <div class="abt-box">
                      <h5>
                        {{ testimonial.first_name }} {{ testimonial.last_name }}
                      </h5>
                      <p>{{ testimonial.designation }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="content-box">
                    <p>
                      {{ testimonial.content }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            v-if="testimonials.length > 1"
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <i class="fas fa-chevron-left"></i>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            v-if="testimonials.length > 1"
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <i class="fas fa-chevron-right"></i>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
    <div class="container achievement-head">
      <span>Achievements</span>
    </div>

    <div class="container-fluid achievement-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-3 split-col">
            <h5>{{ achievements.students }}+</h5>
            <p>Students</p>
          </div>
          <div class="col-md-3 split-col">
            <h5>{{ achievements.programs }}+</h5>
            <p>Courses</p>
          </div>
          <div class="col-md-3 split-col">
            <h5 v-if="achievements.students > 0">
              {{ achievements.students * 2 }}+
            </h5>
            <h5 v-else>
              0
            </h5>
            <p>Trusted parents</p>
          </div>
          <div class="col-md-3">
            <h5>{{ achievements.teachers }}+</h5>
            <p>Expert Faculties</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeService from "@/services/HomeService.js";
import errorLog from "@/errorLog";
import Loader from "@/components/loaders/Loader";
import ComingSoon from "@/components/ComingSoon.vue";
export default {
  name: "Home",
  components: {
    Loader,
    ComingSoon
  },
  computed: {
    isComingSoon() {
      let isComingSoon = JSON.parse(process.env.VUE_APP_COMING_SOON);
      return isComingSoon;
    }
  },
  created() {
    this.loadClasses();
    this.getSubjectCategory();
    this.loadTestimonials();
    this.getAchievements();
    this.getContents();
  },
  data() {
    return {
      testimonials: [],
      classes: [],
      isClassLoader: true,
      subjectCategories: [],
      achievements: [],
      aboveTShapedLearnings: [],
      banners: "",
      tShapedLearning: "",
      BeAMember: ""
    };
  },
  methods: {
    getContents() {
      HomeService.getContents()
        .then(result => {
          this.banners = result.data.banner;
          this.aboveTShapedLearnings = result.data.aboveTShapedLearning;
          this.tShapedLearning = result.data.tShapedLearning;
          this.BeAMember = result.data.BeAMember;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    async loadClasses() {
      await HomeService.getStandards()
        .then(result => {
          this.isClassLoader = false;
          this.classes = result.data;
        })
        .catch(error => {
          this.isClassLoader = false;
          errorLog.log(error);
        });
    },
    async getSubjectCategory() {
      await HomeService.getSubjectCategory()
        .then(result => {
          this.subjectCategories = result.data.data;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    loadTestimonials() {
      this.isTestimonialsLoader = true;
      HomeService.getTestimonials()
        .then(result => {
          this.isTestimonialsLoader = false;
          this.testimonials = result.data.data;
        })
        .catch(error => {
          this.isTestimonialsLoader = false;
          errorLog.log(error);
        });
    },
    getThumbnail: function(image) {
      return require("@/assets/class" + image + ".svg");
    },
    async getAchievements() {
      await HomeService.getAchievements()
        .then(result => {
          this.achievements = result.data;
        })
        .catch(error => {
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "../style/home.scss";
</style>
