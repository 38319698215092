import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import auth from "../middleware/auth";
import moment from "moment";
//import timezone from "@/services/timezone";
import $store from "@/store";
import AuthService from "@/services/AuthService";
<script src="https://cdn.jsdelivr.net/momentjs/2.13.0/moment.min.js"></script>;
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Uni-landing.vue")
  },
  {
    path: "/pupa",
    name: "pupa",
    component: Home
  },
  {
    path: "/course/:slug",
    name: "course",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Course.vue")
  },
  {
    path: "/webinar/:slug",
    name: "webinarDetails",
    component: () => import("../views/WebinarDetails.vue")
  },
  {
    path: "/membership",
    name: "membership",
    component: () => import("../views/Membership.vue")
  },
  {
    path: "/membership/purchase",
    name: "membershipCheckout",
    component: () => import("../views/MembershipCheckout.vue"),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/nest/membership/purchase",
    name: "nestMembershipCheckout",
    component: () => import("../views/NestMembershipCheckout.vue"),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/events",
    name: "webinars",
    component: () => import("../views/WebinarListing.vue")
  },
  {
    path: "/nest",
    name: "Nest",
    component: () => import("../views/Nest.vue")
  },
  {
    path: "/courses",
    name: "courses",
    component: () => import("../views/CourseListing.vue")
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/StudentDashboard.vue"),
    meta: {
      middleware: [auth]
    },
    children: [
      {
        path: "",
        name: "dashboardTab",
        component: () => import("../components/Dashboard.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "certificate",
        name: "certificate",
        component: () => import("../components/Certificate.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "profile",
        name: "profile",
        component: () => import("../components/ProfileTab.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "schedules",
        name: "schedules",
        component: () => import("../components/ClassTab.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "events",
        name: "events",
        component: () => import("../components/EventsTab.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "/studyMaterials/:slug",
        name: "studyMaterials",
        component: () => import("../components/StudyMaterial.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "/pupa",
        name: "pupaDashboardTab",
        component: () => import("../components/PupaDashboard.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "discussions",
        name: "discussions",
        component: () => import("../components/DiscussionBoard.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "discussion/reply/:id",
        name: "discussionReply",
        component: () => import("../components/DiscussionSingle.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "nest/discussions",
        name: "nestDiscussions",
        component: () => import("../components/NestDiscussionBoard.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "nest/discussion/reply/:id",
        name: "nestDiscussionReply",
        component: () => import("../components/NestDiscussionSingle.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "academics",
        name: "academics",
        component: () => import("../components/AcademicTab.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "assignment/:id",
        name: "assignments",
        component: () => import("../components/AssignmentPortal.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "exam/:id",
        name: "exam",
        component: () => import("../components/ExamPortal.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "exam/result/:id",
        name: "examResult",
        component: () => import("../components/ExamResult.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "recordings/:id",
        name: "recordings",
        component: () => import("../components/Recordings.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "recording/:id",
        name: "recording",
        component: () => import("../components/RecordingVideo.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "nest/events",
        name: "nestEvents",
        component: () => import("../components/NestEventTab.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "nest/event/recordings/:id",
        name: "nestRecordings",
        component: () => import("../components/NestRecordings.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "nest/recording/:id",
        name: "nestRecording",
        component: () => import("../components/NestRecordingVideo.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "nest/quizzes",
        name: "nestQuizzes",
        component: () => import("../components/QuizListTab.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "nest/quiz/:id",
        name: "nestQuiz",
        component: () => import("../components/QuizPortal.vue"),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "nest/quiz/result/:id",
        name: "quizResult",
        component: () => import("../components/QuizResult.vue"),
        meta: {
          middleware: [auth]
        }
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/forgot/password",
    name: "forgotPassword",
    component: () => import("../views/ForgotPassword.vue")
  },
  {
    path: "/otp-login/phone",
    name: "otpPhoneLogin",
    component: () => import("../views/OtpPhoneLogin.vue")
  },
  {
    path: "/otp-login/email",
    name: "otpEmailLogin",
    component: () => import("../views/OtpEmailLogin.vue")
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/Register.vue")
  },
  {
    path: "/register-with-phone",
    name: "registerWithPhone",
    component: () => import("../views/RegisterWithPhone.vue")
  },
  {
    path: "/nest/register",
    name: "nestRegister",
    component: () => import("../views/NestRegister.vue")
  },
  {
    path: "/nest/register-with-phone",
    name: "nestRegisterWithPhone",
    component: () => import("../views/NestRegisterWithPhone.vue")
  },
  {
    path: "/password/reset",
    name: "resetPassword",
    component: () => import("../views/ResetPassword.vue")
  },
  {
    path: "/email/verification",
    name: "emailVerification",
    component: () => import("../views/EmailVerification.vue")
  },
  {
    path: "/phone/verification",
    name: "phoneVerification",
    component: () => import("../views/PhoneVerification.vue")
  },
  {
    path: "/course/checkout/:slug",
    name: "courseCheckout",
    component: () => import("../views/CourseCheckout.vue"),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/webinar/checkout/:slug",
    name: "webinarCheckout",
    component: () => import("../views/WebinarCheckout.vue"),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/class/meeting/:id",
    name: "meeting",
    component: () => import("../pages/Meeting.vue"),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/class/event/:id",
    name: "event",
    component: () => import("../pages/Event.vue"),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/membership/payment/success/:id",
    name: "membershipSuccess",
    component: () => import("../views/MembershipSuccess.vue"),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/payment/success/:id",
    name: "paymentSuccess",
    component: () => import("../views/PaymentSuccess.vue"),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/profile",
    name: "PublicProfile",
    component: () => import("../views/PublicProfile.vue")
  },
  {
    path: "/contact-us",
    name: "contactUs",
    component: () => import("../views/ContactUs.vue")
  },
  {
    path: "/:slug",
    name: "content",
    component: () => import("../views/ContentPage.vue")
  }
];

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const user = $store.getters["studentAuth/user"];
  if (user.length != undefined) {
    const expirationTime = $store.getters["studentAuth/expirationTime"];
    if (expirationTime != "") {
      var expirationTimeInMoment = moment(expirationTime).utc();
      var currentTimeInMoment = moment.utc();
      console.log(currentTimeInMoment.diff(expirationTimeInMoment, "minutes"));
      if (currentTimeInMoment.diff(expirationTimeInMoment, "minutes") > 0) {
        var currentPath = window.location.pathname;
        var stringArray = currentPath.split("/");
        var params = "";
        if (stringArray[1] == "nest") {
          params = "Nest";
        } else {
          params = "Pupa";
        }
        $store.dispatch("studentAuth/SetExpirationTime", "");
        AuthService.logout().then(result => {
          $store.dispatch("studentAuth/logout");
          console.log(result);
          router.push({ name: "login", query: { p: params } });
        });
      }
    }
  }
  const time = moment()
    .add(3, "hours")
    .utc();
  // const finalTime = timezone.convertedTimeZone(time);
  $store.dispatch("studentAuth/SetExpirationTime", time);

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const context = {
      from,
      next,
      router,
      to
    };
    const nextMiddleware = nextFactory(context, middleware, 1, to);
    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next();
});
function nextFactory(context, middleware, index, to) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;
  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware, to });
  };
}

export default router;
