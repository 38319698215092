import { createStore } from "vuex";
import Cookies from "js-cookie";
import createPersistedState from "vuex-persistedstate";
import studentAuth from "./modules/studentAuth";
import privilege from "./modules/privilege";

const persistedStateOptions = {
  paths: ["studentAuth"],
  storage: {
    getItem: key => Cookies.get(key),
    setItem: (key, value) =>
      Cookies.set(key, value, {
        expires: 1000000,
        secure: false
      }),
    removeItem: key => Cookies.remove(key)
  }
};
const store = createStore({
  modules: {
    studentAuth: studentAuth,
    privilege: privilege
  },
  plugins: [createPersistedState(persistedStateOptions)]
});

export default store;
