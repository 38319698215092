import axios from "axios";
import store from "@/store/";
// import router from "@/router/";

export default () => {
  const token = store.state.studentAuth.token;
  const Http = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  Http.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response.status == 401) {
        store.dispatch("studentAuth/logout");
        // router.push({ name: "course" }).catch(err => err);
      }
      return Promise.reject(error);
    }
  );
  return Http;
};
