const privilege = {
  namespaced: true,
  state: {
    pupa_member: true,
    nest_member: true
  },
  mutations: {
    PUPA_MEMBER: (state, status) => {
      state.pupa_member = status;
    },
    NEST_MEMBER: (state, status) => {
      state.nest_member = status;
    }
  },
  getters: {
    isPupaMember: state => state.pupa_member,
    isNestMember: state => state.nest_member
  },
  actions: {
    pupaMember: ({ commit }, status) => {
      commit("PUPA_MEMBER", status);
    },
    nestMember: ({ commit }, data) => {
      commit("NEST_MEMBER", data);
    }
  }
};
export default privilege;
