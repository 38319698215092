import store from "@/store/";
export default function auth({ next, router, to }) {
  if (store.getters["studentAuth/isLoggedIn"] != true) {
    store.dispatch("studentAuth/updateNextUrl", to.path);
    var currentPath = window.location.pathname;
    var stringArray = currentPath.split("/");
    var params = "";
    if (stringArray[1] == "nest") {
      params = "Nest";
    } else {
      params = "Pupa";
    }
    return router.push({ name: "login", query: { p: params } });
  } else {
    let user = JSON.parse(store.getters["studentAuth/user"]);
    if (user.email_verified_at == null && user.register_type == "email") {
      return router.push({ name: "emailVerification" });
    } else if (
      user.phone_verified_at == null &&
      user.register_type == "phone"
    ) {
      return router.push({ name: "phoneVerification" });
    }
  }
  return next();
}
