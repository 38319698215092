import Http from "@/services/Http";
export default {
  login(data) {
    return Http().post("login", data);
  },
  register(data) {
    return Http().post("register", data);
  },
  nestRegister(data) {
    return Http().post("nest/register", data);
  },
  logout() {
    return Http().post("logout");
  },
  verifyEmail(data) {
    return Http().post("email/verify", data);
  },
  verifyPhone(data) {
    return Http().post("phone/verify", data);
  },
  resendOTP() {
    return Http().post("otp/send");
  },
  getAttendance() {
    return Http().post("report/attendance");
  },
  getOTP(data) {
    return Http().post("/login/otp/get", data);
  },
  forgotPassword(data) {
    return Http().post("password/forgot", data);
  },
  resetPassword(data) {
    return Http().post("password/reset", data);
  }
};
